<template>
	<div>
		<div class="container-preco">
			<div v-if="isInterState" class="preco-final head-bold color-grey-3c">
				{{ majorPrice | formatarMoeda }}
			</div>

			<div v-else>
				<div class="desconto-wrapper" v-if="haveDiscount">
					<div class="precode color-grey-6a vw-head-light">
						de: <span class="line-through">{{ finalPrice | formatarMoeda }}</span>
					</div>

					<div class="flex precopor head-bold color-grey-3c">
						<span class="color-grey-6a vw-head-light" style="margin-right: 4px">por:</span> {{ discountPrice | formatarMoeda }}
						<span class="desconto" :class="discountClass" v-if="showDiscountBadge">-{{ discount }}%</span>
					</div>
				</div>
				<div class="preco head-bold" v-else>
					{{ discountPrice | formatarMoeda }}
				</div>
			</div>

			<div v-if="multiplasConcessionarias" class="outras-ofertas">
				<button @click="$emit('botaoFornecidoPorEscolherOutraConcessionariaClick')" class="flex gap-2 items-center">
					<svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M45.1106 0.5H47.0006V1.44L43.1106 5.34C46.7284 9.40992 46.9793 15.4647 43.7106 19.82C43.1538 20.5628 43.2261 21.6016 43.8806 22.26C44.609 22.9932 44.609 24.1768 43.8806 24.91L22.5506 46.24C20.8646 47.9208 18.1366 47.9208 16.4506 46.24L1.2606 31.05C-0.420202 29.364 -0.420202 26.636 1.2606 24.95L22.5506 3.62C22.9015 3.26765 23.3783 3.06957 23.8756 3.06957C24.3729 3.06957 24.8497 3.26765 25.2006 3.62C25.8564 4.27756 26.8954 4.3542 27.6406 3.8C31.7977 0.654505 37.5565 0.720319 41.6406 3.96L45.1106 0.5ZM42.3806 23.62C41.0752 22.2457 40.9607 20.1269 42.1106 18.62C44.7654 15.0482 44.5782 10.1106 41.6606 6.75L38.0006 10.41C39.0492 11.8978 38.9422 13.9102 37.7417 15.2784C36.5412 16.6466 34.5597 17.0143 32.9482 16.168C31.3367 15.3217 30.5147 13.4817 30.9596 11.7168C31.4045 9.95178 33.0005 8.72129 34.8206 8.74C35.3955 8.74014 35.9629 8.87004 36.4806 9.12L40.2106 5.34C36.8549 2.81833 32.2363 2.81833 28.8806 5.34C28.2148 5.84943 27.3989 6.12374 26.5606 6.12C25.585 6.13701 24.6401 5.77909 23.9206 5.12L2.6806 26.36C1.77524 27.2659 1.77524 28.7341 2.6806 29.64L17.8606 44.82C18.7665 45.7254 20.2347 45.7254 21.1406 44.82L42.3806 23.62ZM36.7406 12.62C36.7406 13.6804 35.881 14.54 34.8206 14.54C33.7602 14.54 32.9006 13.6804 32.9006 12.62C32.9006 11.5596 33.7602 10.7 34.8206 10.7C35.3368 10.6999 35.8313 10.9077 36.1926 11.2764C36.5538 11.6452 36.7514 12.1439 36.7406 12.66V12.62ZM11.3825 30.4052L25.4256 16.3621L26.8398 17.7763L12.7967 31.8194L11.3825 30.4052ZM16.1951 35.2147L26.6249 24.7849L28.0391 26.1991L17.6093 36.6289L16.1951 35.2147Z"
							fill="white"
						/>
					</svg>
					<span class="head-regular">Veja outros preços para este produto</span>
				</button>
			</div>

			<!-- Retire gratis -->
			<div class="retire__gratis hidden" v-if="vendidoPor">
				<div class="head-bold">
					Retire grátis em: <span class="head-bold color-grey-3c">{{ vendidoPor }}</span>
				</div>
			</div>
			<div class="qtd__stock head-regular color-grey-6a hidden">{{ getStockText(quantity) }}</div>

			<div v-if="showParcelas" class="showparcelas">
				<div class="container-maximo-parcelas" v-if="parcelaData">
					<span class="head-regular em-ate">Em até</span>
					<span class="head-bold de"
						>{{ parcelaData.parcela_maxima }}x de
						<span v-if="isInterState">{{ parcelaData.valor_majorado_parcelado | formatarMoeda }}</span>
						<span v-else>{{ parcelaData.valor_parcelado | formatarMoeda }}</span></span
					>
					<span class="head-regular green sem-juros">sem juros</span>
				</div>
				<a href.prevent="#" class="extra-info head-regular" @click="triggerCondicoesParcelamento">Informações sobre condições de parcelamento</a>
			</div>
		</div>

		<!-- Condições de parcelamento -->
		<Modal :visibility="modalCondicoesParcelamento" @closeModal="modalCondicoesParcelamento = false" width="500">
			<div class="flex flex-column align-items-center static-mt-24">
				<div class="head-bold color-dark text-center font-32 static-mb-16">Condições de parcelamento</div>
				<div class="head-regular font-16 text-center static-mb-16 color-grey-6f">
					O parcelamento máximo pode variar em função do valor de parcela mínima de R$ 20 em produtos e do parcelamento máximo definido por cada concessionária.<br />
					O valor mínimo para compras com PIX é de R$50 por concessionária, somado valor de produtos e frete.
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
import { getDealershipsText } from "@/utils/product"
import Modal from "@/components/Common/Modal"
export default {
	components: {
		Modal,
	},
	data() {
		return {
			modalCondicoesParcelamento: false,
		}
	},
	props: {
		vendidoPor: {
			type: String,
		},
		finalPrice: {
			type: Number,
		},
		majorPrice: {
			type: Number,
		},
		discountPrice: {
			type: Number,
		},
		discount: {
			type: Number,
		},
		geolocation: {
			type: Object,
		},
		dealerUf: {
			type: String,
		},
		showParcelas: {
			type: Boolean,
		},
		maximoParcelas: {
			type: Boolean | Number,
		},
		priceClass: {
			type: String,
			default: "font-32 head-bold",
		},
		discountClass: {
			type: String,
			default: "",
		},
		parcelaData: {
			type: Object,
		},
		quantity: {
			type: Number,
		},
		multiplasConcessionarias: {
			type: Boolean,
		},
	},
	methods: {
		getStockText(quantity) {
			return getDealershipsText(quantity)
		},
		triggerCondicoesParcelamento() {
			this.modalCondicoesParcelamento = !this.modalCondicoesParcelamento

			if (process.env.TOKEN_EPA) {
				let $epa = new window._epa({
					cKey: process.env.TOKEN_EPA,
				})

				$epa.send("verificar-condicoes-parcelamento")
			}
		},
	},
	computed: {
		isInterState() {
			if (this.geolocation) {
				if (this.dealerUf === this.geolocation.uf) {
					return false
				}
			}
			return true
		},
		haveDiscount() {
			if (this.finalPrice > this.discountPrice) {
				return true
			}
			return false
		},
		showDiscountBadge() {
			if (this.haveDiscount && this.discount > 0) {
				return true
			}
			return false
		},
	},
}
</script>

<style lang="scss" scoped>
.desconto {
	background-color: #000;
	color: #fff;
	margin-left: 8px;
	display: flex;
	align-items: center;
	padding: 4px 8px;
	font-size: 14px;
	line-height: 14px;
	@media (min-width: 1025px) {
		font-size: 20px;
		line-height: 24px;
	}
}
// .desconto-wrapper{
// 	@media(min-width:1025px){
// 		display: block;
// 	}
// }
.product__list {
	display: grid;
	gap: 16px;
	grid-template-columns: 1fr 1fr;
	&__item {
		padding: 16px;
		border: 1px solid #f1f1f1;
		border-radius: 10px;
		&:hover {
			border: 1px solid var(--primary_color);
		}
		&__action {
			&__local {
				display: flex;
				font-size: 12px;
				line-height: 15px;
				svg {
					height: 16px;
					width: 16px;
					margin-right: 4px;
				}
			}
			&__price {
				font-size: 16px;
				line-height: 19px;
				color: var(--text_dark_color);
				font-weight: bold;
				font-family: "VWHead-Bold", sans-serif;
			}
		}
		&__img {
			width: 100px;
			height: 100px;
			margin: 0 auto 8px;
			background-size: cover;
			border: 1px solid #f1f1f1;
		}
		&__desc {
			margin-bottom: 40px;
			min-height: 60px;
			word-break: break-all;
			font-size: 12px;
			line-height: 15px;
			font-weight: 300;
			font-family: "VWHead-Regular", sans-serif;
		}
	}
}
@media (min-width: 1200px) {
	.list__wrapper {
		display: grid;
		grid-template-columns: 306px auto;
		gap: 30px;
	}
	.product__list {
		display: grid;
		gap: 30px;
		grid-template-columns: 1fr 1fr 1fr;
		&__item {
			padding: 24px;
			border: 1px solid #f1f1f1;
			border-radius: 10px;
			&:hover {
				border: 1px solid var(--primary_color);
			}
			&__action {
				&__local {
					display: flex;
					font-size: 12px;
					line-height: 15px;
				}
				&__price {
					font-size: 16px;
					line-height: 19px;
					color: var(--text_dark_color);
					font-weight: bold;
				}
			}
			&__img {
				width: 224px;
				height: 224px;
				margin: 0 auto 16px;
				background-size: cover;
				border: 1px solid #f1f1f1;
			}
			&__desc {
				margin-bottom: 40px;
				min-height: 80px;
				word-break: break-word;
				font-size: 16px;
				line-height: 19px;
			}
		}
	}
}
.preco {
	font-size: 20px;
	line-height: 24px;
	color: #4e4f50;
	@media (min-width: 768px) {
		font-size: 32px;
		line-height: 39px;
	}
}
.green {
	color: #029640;
}
.em-ate {
	font-size: 12px;
	line-height: 15px;
	color: #6f6f6f;
	@media (min-width: 768px) {
		font-size: 20px;
		line-height: 24px;
	}
}
.de {
	font-size: 12px;
	line-height: 15px;
	color: #3c484d;
	@media (min-width: 768px) {
		font-size: 20px;
		line-height: 24px;
	}
}
.sem-juros {
	font-size: 12px;
	line-height: 15px;
	@media (min-width: 768px) {
		font-size: 20px;
		line-height: 24px;
	}
}
.extra-info {
	font-size: 10px;
	line-height: 12px;
	text-decoration-line: underline;
	color: #6f6f6f;
	cursor: pointer;
	@media (min-width: 768px) {
		font-size: 16px;
		line-height: 19px;
	}
}
.precode {
	font-size: 12px;
	line-height: 15px;
	margin-bottom: 4px;
	@media (min-width: 1025px) {
		font-size: 20px;
		line-height: 24px;
	}
}
.showparcelas {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.box-flutuante {
	.extra-info {
		display: none;
	}
}
.preco-final {
	font-size: 20px;
	line-height: 24px;
	@media (min-width: 768px) {
		font-size: 32px;
		line-height: 39px;
		margin-bottom: 16px;
	}
}
.precopor {
	font-size: 20px;
	line-height: 24px;
	@media (min-width: 768px) {
		font-size: 32px;
		line-height: 39px;
		margin-bottom: 16px;
	}
}
.list {
	.preco-final {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 4px;
	}
	.showparcelas {
		display: none;
	}
	.precopor {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 8px;
	}
	.precode {
		font-size: 12px;
		line-height: 15px;
		margin-bottom: 4px;
	}
	.desconto {
		font-size: 12px;
		line-height: 12px;
	}
	.qtd__stock {
		display: block;
		font-size: 12px;
		line-height: 15px;
	}
	.preco {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 4px;
	}
	.retire__gratis {
		display: block;
		div {
			color: #029640;
			font-size: 12px;
			line-height: 15px;
		}
	}
}
.carousel {
	.qtd__stock {
		display: block;
		font-size: 12px;
		line-height: 15px;
	}
}
.productpage__similar {
	.precopor {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 8px;
	}
	.precode {
		font-size: 12px;
		line-height: 15px;
		margin-bottom: 4px;
	}
	.desconto {
		font-size: 12px;
		line-height: 12px;
	}
	.preco-final {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 4px;
	}
	.showparcelas {
		display: none;
	}
	.qtd__stock {
		display: block;
		font-size: 12px;
		line-height: 15px;
	}
	.preco {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 4px;
	}
	.retire__gratis {
		display: block;
		div {
			color: #029640;
			font-size: 12px;
			line-height: 15px;
		}
	}
}
.outras-ofertas {
	background-color: #029640;
	border-radius: 8px;
	width: 100%;
	padding: 8px;
	font-size: 16px;
	line-height: 19px;
	color: #fff;
	margin: 6px 0;
	transition: all 0.4s ease-in-out;
	display: inline-block;
	width: inherit;
	svg {
		height: 16px;
		width: 16px;
	}
	button {
		span {
			font-size: 12px;
		}
	}

	&:hover {
		background-color: #036b2e;
	}
	@media (min-width: 768px) {
		padding: 8px 16px;
		svg {
			height: 32px;
			width: 32px;
		}
		button {
			text-align: center;
			span {
				font-size: 14px;
			}
		}
	}
}
.container-preco {
	margin-bottom: 16px;
	@media (min-width: 1220px) {
		margin-bottom: 0;
	}
}
</style>
